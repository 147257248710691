import { defineStore } from 'pinia';
import { ref, computed } from '@nuxtjs/composition-api';
import { Wishlist } from '@gemini-vsf/api-client';

export const useWishlistStore = defineStore('wishlist', () => {
  const wishlistLoading = ref<boolean>(false);
  const wishlist = ref<Partial<Wishlist>>();
  const itemsInWishlist = ref<Record<string, string>>();
  const wishlistItemsCount = ref<number>();
  const wishlistNextPageToken = computed(() => wishlist?.value?.items?.next_page_token);

  const setWishlistLoading = (loading: boolean) => {
    wishlistLoading.value = loading;
  };

  const setWishlist = (data: Wishlist) => {
    wishlist.value = data;
    wishlistItemsCount.value = data?.items_count || 0;
  };

  const setItemsInWishlist = (items: Record<string, string>) => {
    itemsInWishlist.value = {
      ...itemsInWishlist.value,
      ...items,
    };
  };

  const removeItemsFromWishlist = (items: string[]) => {
    items.forEach((item) => {
      delete itemsInWishlist.value[item];
    });
  };

  const setWishlistItemsCount = (count: number) => {
    wishlistItemsCount.value = count;
  };

  return {
    wishlist,
    setWishlist,
    itemsInWishlist,
    setItemsInWishlist,
    wishlistItemsCount,
    setWishlistItemsCount,
    removeItemsFromWishlist,
    setWishlistLoading,
    wishlistLoading,
    wishlistNextPageToken,
  };
});

/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable import/extensions */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { Plugin } from '@nuxt/types';
import countries from 'i18n-iso-countries';
import it from 'i18n-iso-countries/langs/it.json';
import en from 'i18n-iso-countries/langs/en.json';

countries.registerLocale(it);
countries.registerLocale(en);

const plugin: Plugin = (context, inject) => {
  inject('i2c', (iso: string, localeParameter?: string): string => {
    const locale = localeParameter || context.i18n?.localeProperties?.code;
    return countries.getName(iso, locale);
  });
};

export default plugin;

import Vue from 'vue';

const vVisible = {
  install(Vue) {
    Vue.directive('visible', (element, binding) => {
      const value = binding.value;

      element.style.visibility = value ? 'visible' : 'hidden';
    });
  },
};

if (typeof window !== 'undefined') {
  Vue.use(vVisible);
}

export default vVisible;

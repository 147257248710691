/* eslint-disable no-await-in-loop */
// eslint-disable-next-line import/no-extraneous-dependencies
import axios from 'axios';

export const getListUrlRewritesByTargetPaths = async (grn) => {
  const geminiData = await axios({
    method: 'post',
    url: process.env.GEMINI_URL_MANAGER_ENDPOINT,
    data: {
      targetPaths: [grn],
      tenantId: process.env.TENANT_ID,
    },
    headers: {
      Authorization: `Bearer ${process.env.GEMINI_RESOURCE_JWT}`,
    },
  });
  if (geminiData?.data?.urlRewrites) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return geminiData.data.urlRewrites;
  }
  return [];
};
export default {
  path: '/api/getgeminidata',
  async handler(req, res) {
    try {
      const urlData = await getListUrlRewritesByTargetPaths(req.query.grn);
      res.send(urlData);
    } catch (e) {
      res.send(e);
    }
  },
};

import cookieNameEnum from '~/enums/cookieNameEnum';
import c from 'cookie';

export default ({ app, req }) => {
  if (req) {
    const appCookies = app?.$cookies;
    if (appCookies && !appCookies.get(cookieNameEnum.acceptLanguageCookieName)) {
      appCookies.set(cookieNameEnum.acceptLanguageCookieName, 'it-IT');
    }
    if (req?.headers?.cookie) {
      const cookiesObject = c.parse(req?.headers?.cookie);
      cookiesObject[cookieNameEnum.acceptLanguageCookieName] = 'it-IT';
      let cookieString = '';
      Object.keys(cookiesObject).forEach((key) => {
        cookieString += `${key}=${cookiesObject[key]}; `;
      });
      req.headers.cookie = cookieString.trim().slice(0, -1);
    }
  }
};

/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComputedRef } from '@nuxtjs/composition-api';
import type { Wishlist } from '@gemini-vsf/api-client';
import { ItemInWishlist } from '@gemini-vsf/api-client/lib/types/GraphQL';

export const wishlistCookieName = 'gemini-wishlist';

export interface UseWishlist {
  wishlist: ComputedRef<Partial<Wishlist>>;
  wishlistItemsCount: ComputedRef<number>;
  wishlistLoading: ComputedRef<boolean>;
  createWishlist: (loadAfterCreation: boolean) => Promise<string>;
  loadWishlist: (uid?: string) => Promise<void>;
  toggleWishlistSidebar: () => Promise<void>;
  loadWishlistItemsCount: (uid?: string) => Promise<void>;
  addItemToWishlist: (sku: string, quantity: number, uid?: string) => Promise<void>;
  removeItemFromWishlist: (sku: string, uid?: string) => Promise<void>;
  toggleWishlistItem: (sku: string, uid?: string) => Promise<void>;
  loadItemsInWishlist: (items: string[], uid?: string) => Promise<void>;
  itemsInWishlist: ComputedRef<Record<string, string>>;
}

export type WishlistApiResult = {
  data?: {
    createWishlist?: Partial<Wishlist>;
    wishlist?: Partial<Wishlist>;
    addItemToWishlist?: Partial<Wishlist>;
    removeItemFromWishlist?: Partial<Wishlist>;
    itemsInWishlist?: ItemInWishlist[];
  };
  errors?: Array<any>;
};

import cookieNameEnum from '~/enums/cookieNameEnum';
import c from 'cookie';

const MARKET_GRN = 'grn:channelmanager:market::65844569ea12e52585591fe3';

export default ({ app, req, $config }) => {
  if (req) {
    const market = $config?.marketGrn || MARKET_GRN;
    const appCookies = app?.$cookies;
    if (appCookies && !appCookies.get(cookieNameEnum.marketCookieName)) {
      appCookies.set(cookieNameEnum.marketCookieName, market);
    }
    if (req?.headers?.cookie) {
      const cookiesObject = c.parse(req?.headers?.cookie);
      cookiesObject[cookieNameEnum.marketCookieName] = market;
      let cookieString = '';
      Object.keys(cookiesObject).forEach((key) => {
        cookieString += `${key}=${cookiesObject[key]}; `;
      });
      req.headers.cookie = cookieString.trim().slice(0, -1);
    }
  }
};

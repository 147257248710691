export enum CheckoutStateReferences {
  Loading = 'checkout-loading-',
  Step = 'checkout-current-step-',
  User = 'checkout-user-',
  Shipping = 'checkout-shipping-',
  ShippingAddressId = 'checkout-shipping-address-id-',
  ShippingMethod = 'checkout-shipping-method-',
  Billing = 'checkout-billing-',
  BillingAddressId = 'checkout-billing-address-id-',
  Payment = 'checkout-payment-',
  AvailableShippingMethods = 'checkout-available-shipping-',
  PlacingOrder = 'checkout-placing-order',
  Generic = 'checkout-generic-',
  StepLoading = 'checkout-step-loading-',
  BillingCountry = 'checkout-billing-country-',
  PlaceOfBirth = 'checkout-place-of-birth-',
  DateOfBirth = 'checkout-date-of-birth-',
  FiscalCode = 'checkout-fiscal-code-',
  billingAdditionalDataValidation = 'billing-additional-data-validation-',
}

export enum CheckoutStep {
  UserAccount = 'user-account',
  Shipping = 'shipping',
  Billing = 'billing',
  Payment = 'payment',
}

export enum GeminiRestEndpoints {
  GetOrder = 'https://dom.api.gogemini.io/order.Order/GetOrder',
  GetOrderByOrderNumber = 'https://dom.api.gogemini.io/order.Order/GetOrderByOrderNumber',
  FindCustomerByEmail = 'https://cdp.api.gogemini.io/customer.Customer/FindByEmail',
  FindCustomerById = 'https://cdp.api.gogemini.io/customer.Customer/FindById',
}

export enum GeminiEntityPrefixes {
  WishlistItem = 'grn:wishlist:wishlistitem::',
  ProductList = 'grn:product-list:product-list::',
}
